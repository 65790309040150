<div>

    <!--==========================
    Hero Section
    ============================-->
    <section id="hero">
        <div class="hero-container">
            <div class="wow fadeIn">
                <div class="hero-logo">
                    <img class="" src="/assets/img/logo-hero.svg" alt="">
                </div>
                <h1>Dein kaffeetastisches Erlebnis</h1>
                <h2>Wir verhelfen Dir zu dem Kaffee, den Du liebst </h2>
                <!--<span class="rotating">dem Kaffee den Du liebst, fairen Preisen, gleichbleibender Qualität</span></h2>-->
                <div class="actions">
                    <a class="btn-services" href="#about">Mehr erfahren</a>
                    <!--                <a  class="btn-services"></a>-->
                    <a onclick="window.location.href='https://app.getkahawa.com'" class="btn-get-started">Login</a>
                </div>
            </div>
        </div>
    </section>

    <!--==========================
    About Section
    ============================-->
    <section id="about">
        <div class="container wow fadeInUp">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="section-title">Was ist kahawa?</h3>
                    <h2 class="about-subtitle"><a href="restaurateurs">Weitere Informationen für Gastronomen</a></h2>
                    <div class="section-title-divider"></div>
                </div>
            </div>
        </div>

        <div class="container about-container wow fadeInUp">
            <div class="row about-row">
                <div class="col-lg-6 text-center about-video">
                    <iframe class="dashed" width="100%" height="305px" src="https://www.youtube.com/embed/BJ0-mwesap8"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
                <div class="col-md-6 about-content">
                    <h2 class="about-title">kahawa – einfach Dein Kaffee</h2>
                    <p class="about-text">
                        Wir bei kahawa kümmern uns darum, dass Du überall Deinen persönlichen Lieblingskaffee bekommen
                        kannst.<br>
                        Egal wo Du bist – an Deinem Arbeitsplatz, im Coworking-Space oder beim Bäcker –
                        mit kahawa bekommst Du immer Deinen Kaffee.
                    </p>
                    <p class="about-text">
                        Erstelle Deinen Kaffee und wir kümmern uns um den Rest: Ob Bezahlung oder die Suche nach dem
                        nächsten Spot – wir helfen Dir.
                    </p>
                </div>
            </div>

            <div class="row about-row">
                <div class="col-lg-6 text-center about-img">
                    <img class="dashed" src="/assets/img/about-1.jpg" alt="">
                </div>
                <div class="col-md-6 about-content">
                    <h2 class="about-title">Personalisierter Kaffee - egal wo Du bist</h2>
                    <p class="about-text" matTooltip="Info about the action">
                        Deinen persönlichen Lieblingskaffee kannst Du ganz einfach in unserer
                        <a target="_blank" style="color: var(--beige)" href="https://app.getkahawa.com"><u>App</u></a>
                        erstellen.
                        Anschließend gehst Du mit Deinem Handy oder einem NFC-fähigen Gerät

                        an den Kaffeevollautomaten und wir kümmern uns um die Zubereitung Deines
                        Kaffees.<br>
                        Neue NFC-Geräte kannst Du ganz einfach an der Kaffeemaschine hinzufügen. Folge dafür einfach den
                        Anweisungen an der Maschine.
                    </p>

                    <p class="about-text">
                        Wir kümmern uns darum, dass die Qualität Deines Kaffees an allen Automaten gleich bleibt.
                        So kannst Du Deine ganz persönlichen Kaffeevorlieben erforschen und das unglaubliche
                        Geschmacks-Spektrum des Getränks entdecken.
                    </p>
                </div>
            </div>
            <!--        <div class="row about-row">-->
            <!--            <div class="col-lg-6 text-center about-img">-->
            <!--                <img class="dashed" src="/assets/img/about-2.jpg" alt="">-->
            <!--            </div>-->
            <!--            <div class="col-md-6 about-content">-->
            <!--                <h2 class="about-title">So einfach gehts</h2>-->
            <!--                <p class="about-text">-->
            <!--                    Wir sind aktuell in der Pilotphase und in einigen Coworking-Spaces vertreten.-->
            <!--                    Wenn Du mitmachen möchtest, kannst Du Dir <a onclick="window.location.href='https://app.getkahawa.com'">hier</a>-->
            <!--                    einen Account anlegen und Deine Lieblingskaffees zusammenstellen.-->
            <!--                    Falls Du einen coolen Spot kennst und kahawa gerne dort hinbringen möchtest, <a href="start#contact">schreib uns</a> gerne an.-->
            <!--                </p>-->
            <!--                <p class="about-text">-->
            <!--                    Um Deinen eigenen, selbst-konfigurierten Kaffee am Vollautomaten zu bekommen, musst Du nur-->
            <!--                    Deine NFC-fähiges Gerät-->
            <!--                    <material-icon-tooltip>-->
            <!--                        nahezu alle <b>Smartphones</b>, <br>-->
            <!--                        gängige NFC Karten/-Chips wie z.B. digitale Schlüssel oder Bezahlkarten (kein VISA)-->

            <!--                        <br><br>-->
            <!--                        Du bist also vermutlich bereits ready-to-go 😊-->
            <!--                    </material-icon-tooltip>-->
            <!--                    an das Lesegerät halten. Die Zubereitung deines Kaffees wird dann sofort gestartet.-->
            <!--                </p>-->
            <!--                <p class="about-text">-->
            <!--                    Auch wenn Du noch nicht bei uns registriert bist,-->
            <!--                    kannst Du einfach Dein NFC-fähiges Gerät an die Kaffeemaschine halten:-->
            <!--                    Mit einem QR-Code lotsen wir Dich dann durch die Registrierung.-->
            <!--                </p>-->
            <!--            </div>-->
            <!--        </div>-->
        </div>
    </section>

    <!--==========================
    Services Section
    ============================-->
    <section id="services">
        <div class="container wow fadeInUp">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="section-title">Unsere Services</h3>
                    <div class="section-title-divider"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-coffee"></i></div>
                    <h4 class="service-title">Individueller Kaffee</h4>
                    <p class="service-description">
                        Bekomme genau den Kaffee, den Du liebst – sei es Stärke, Temperatur oder Schaum-Menge.
                    </p>
                </div>
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-money"></i></div>
                    <h4 class="service-title">Faire Preise</h4>
                    <p class="service-description">
                        Heute Lust auf milderen Kaffee? Nimm einfach ein paar weniger Bohnen und bezahle weniger – nur
                        das, was Du verbrauchst.
                    </p>
                </div>
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-paper-plane"></i></div>
                    <h4 class="service-title">Innovativer Bestellprozess</h4>
                    <p class="service-description">
                        Halte Deine Karte an das Lesegerät der Kaffeemaschine. Das ist alles – schon wird Dein Kaffee
                        zubereitet.
                        Alles andere kannst Du vorher – egal wo Du bist – mit unserer App machen.
                    </p>
                </div>
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-leaf"></i></div>
                    <h4 class="service-title">Vegan und Laktosefrei – coming soon</h4>
                    <p class="service-description">
                        Uns ist es wichtig, dass Du Dich wohlfühlst – ideell wie gesundheitlich.
                        Daher bieten wir Dir alle Möglichkeiten, Deinen individuellen Kaffee zu gestalten.
                    </p>
                </div>
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-map-o"></i></div>
                    <h4 class="service-title">Coffee-Finder</h4>
                    <p class="service-description">
                        Falls Du Dich fragst, wo es ausgefalleneren Kaffee mit Karamell- oder Kürbis-Geschmack
                        gibt,
                        hilft Dir unser Coffee-Finder, den richtigen Spot zu finden.
                    </p>
                </div>
                <div class="col-lg-4 service-item">
                    <div class="service-icon"><i class="fa fa-shopping-bag"></i></div>
                    <h4 class="service-title">Genie&#x1e9e;e Sonderangebote – coming soon</h4>
                    <p class="service-description">
                        Rabatt zur vorlesungsfreien Zeit? Pumpkin-Spice-Latte zu Halloween? Der dritte Kaffee am Tag zum
                        halben Preis? Wir sagen Dir Bescheid!
                    </p>
                </div>
            </div>
        </div>
    </section>

    <!--  &lt;!&ndash;==========================-->
    <!--  Subscribe Section-->
    <!--  ============================&ndash;&gt;-->
    <!--  <section id="subscribe">-->
    <!--    <div class="container wow fadeInUp">-->
    <!--  &lt;!&ndash;        <div class="row">&ndash;&gt;-->
    <!--  &lt;!&ndash;            <div class="col-md-12">&ndash;&gt;-->
    <!--  &lt;!&ndash;                &ndash;&gt;-->
    <!--  &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--  &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--  -->
    <!--        <div class="row">-->
    <!--            <div class="col-md-8">-->
    <!--                <h3 class="subscribe-title">Bleibe up to Date</h3>-->
    <!--                <p class="subscribe-text">-->
    <!--                    Wir sind ein StartUp und haben noch tausend Ideen,<br>-->
    <!--                    wie wir nicht nur die Kaffeewelt eine Bessere machen wollen.<br><br>-->
    <!--  -->
    <!--                    Wenn Du gerne mehr über unsere Entwicklung mitbekommen und<br>-->
    <!--                    nie wieder etwas verpassen möchtest, melde Dich gerne für unseren Newsletter an.-->
    <!--                </p>-->
    <!--            </div>-->
    <!--  &lt;!&ndash;            <div class="col-md-4 subscribe-btn-container">&ndash;&gt;-->
    <!--  &lt;!&ndash;                <a class="subscribe-btn" href="#">Jetzt Abonnieren</a>&ndash;&gt;-->
    <!--  &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--            <div class="col-md-3">-->
    <!--                <br>-->
    <!--                <div class="form">-->
    <!--                    <div id="subscribemessage">Du gehörst nun zum Abonnementenkreis. Willkommen!</div>-->
    <!--                    <div id="errormessage"></div>-->
    <!--                    <form action="" method="post" role="form" class="subscribeForm">-->
    <!--                        <div class="form-group">-->
    <!--                            <input type="text" name="name" class="form-control" id="name" placeholder="Dein Name" data-rule="minlen:4" data-msg="Gib bitte mindestens 4 Zeichen ein" />-->
    <!--                            <div class="validation"></div>-->
    <!--                        </div>-->
    <!--                        <div class="form-group">-->
    <!--                            <input type="email" class="form-control" name="email" id="email" placeholder="Deine Email" data-rule="email" data-msg="Bitte gebe eine valide Emailadresse an" />-->
    <!--                            <div class="validation"></div>-->
    <!--                        </div>-->
    <!--                        <div class="text-center"><button type="submit">Jetzt Abonnieren</button></div>-->
    <!--                    </form>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--  </section>-->

    <!--==========================
    Team Section
    ============================-->
    <section id="team">
        <div class="container wow fadeInUp">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="team-title">Dein Ansprechpartner</h3>
                    <div class="section-title-divider"></div>
                </div>
            </div>
            <div class="row justify-content-center">
                <!--            <div class="col-md-3">-->
                <div class="member">
                    <div class="pic"><img src="/assets/img/team-1.jpg" alt=""></div>
                    <h4>Jan Lukas Braje</h4>
                    <span>Gründer</span>

                </div>
                <!--            </div>-->
            </div>
            <div class="row team-row justify-content-center">
                <div class="col-md-9">
                    Vor einigen Jahren hat Jan an der Columbia University of New York "Food Printing" kennengelernt und
                    hat sich sofort in das Thema verliebt, die Lebensmittelbranche mit IT und Ingenieurskunst zu
                    revolutionieren.
                    Nur wenig später haben sich die Mitstreiter versammelt, um gemeinsam die Idee anzugehen. <br>

                    Unter anderem durch Aufenthalte von Team-Mitgliedern/Co-Gründern in Tansania ist die Kunst der
                    Kaffeezubereitung
                    in unser Bewusstsein gekommen. Wegen seiner geschmacklichen Vielseitigkeit waren wir
                    uns sofort einig, dass der Kaffee der perfekte Drink ist, um ihn Euch durch eine clevere IT-Lösung
                    zugänglicher zu machen. Gemeinsam haben wir so die Vision entwickelt, das Kaffeeerlebnis in der
                    Selbstbedienungsgastronomie komplett neu zu gestalten. <br> <br>

                    Mit dieser Vision haben wir inzwischen so einige Meilensteine gemeistert und freuen uns über
                    das stetig wachsende Interesse an individuellem Kaffee und immer weitere Einsatzorte – um Dir Deinen
                    Kaffee
                    noch näherzubringen. <br> <br>

                    Wir freuen uns immer über Feedback, Ideen, Anregungen oder jede andere Unterstützung.<br>
                    Weiter unten kannst Du Kontakt mit uns aufnehmen.<br><br>
                    Ach ja... und falls Du Dich fragst: Kahawa ist Swahili und bedeutet "Kaffee".
                </div>
            </div>
        </div>
    </section>

    <!--==========================
    Contact Section
    ============================-->
    <section id="contact">
        <div class="container wow fadeInUp">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="section-title">Kontakt</h3>
                    <div class="section-title-divider"></div>
                    <p class="section-description">
                        Wir freuen uns über Dein Feedback und Deine Ideen. <br><br>
                        Wenn Du Fragen hast, mitmachen möchtest oder Dir einfach nur etwas<br>
                        Kaffeemäßiges von der Seele reden möchtest – immer her damit!
                    </p>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3">
                    <div class="info">
                        <div>
                            <i class="fa fa-map-marker"></i>
                            <p>Theaterstraße 4<br>01067 Dresden<br>Deutschland</p>
                        </div>

                        <div>
                            <i class="fa fa-envelope"></i>
                            <p>info@getkahawa.com</p>
                        </div>

                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="form">
                        <div id="sendmessage">Deine Nachricht wurde versendet. Danke Dir!</div>
                        <div id="errormessage">Deine Nachricht konnte leider nicht versendet werden. Versuche es bitte
                            in Kürze erneut!
                        </div>

                        <form [formGroup]="form" (ngSubmit)="onClickSubmit()">
                            <div class="form-group">
                                <input id="name"
                                       type="text"
                                       class="form-control"
                                       placeholder="Dein Name"
                                       formControlName="name"
                                       [class.valid]="form.get('name').valid && (form.get('name').dirty || form.get('name').touched)"
                                       [class.invalid]="form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)"/>
                                <div *ngIf="form.get('name').invalid && form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                                    <small class="text-danger" *ngIf="form.get('name').hasError('required')">
                                        Bitte gib Deinen Namen an.
                                    </small>
                                </div>
                            </div>

                            <div class="form-group">
                                <input id="email"
                                       type="email"
                                       class="form-control"
                                       placeholder="Deine Email"
                                       formControlName="email"
                                       [class.valid]="form.get('email').valid && (form.get('email').dirty || form.get('email').touched)"
                                       [class.invalid]="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)"/>
                                <div *ngIf="form.get('email').invalid && form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                                    <small class="text-danger" *ngIf="form.get('email').hasError('required')">
                                        Bitte gib eine valide Emailadresse an.
                                    </small>
                                    <small class="text-danger" *ngIf="form.get('email').hasError('email')">
                                        Bitte gib eine valide Emailadresse an.
                                    </small>
                                </div>
                            </div>
                            <div class="form-group">
                                <input id="subject"
                                       type="text"
                                       class="form-control"
                                       placeholder="Betreff"
                                       formControlName="subject"
                                       [class.valid]="form.get('subject').valid && (form.get('subject').dirty || form.get('subject').touched)"
                                       [class.invalid]="form.get('subject').invalid && (form.get('subject').dirty || form.get('subject').touched)"/>
                                <div *ngIf="form.get('subject').invalid && form.get('subject').errors && (form.get('subject').dirty || form.get('subject').touched)">
                                    <small class="text-danger" *ngIf="form.get('subject').hasError('required')">
                                        Bitte nenne uns einen aussagekräftigen Betreff.
                                    </small>
                                    <small class="text-danger" *ngIf="form.get('subject').hasError('minlength')">
                                        Bitte nenne uns einen aussagekräftigeren Betreff.
                                    </small>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea id="message"
                                          class="form-control"
                                          name="message"
                                          formControlName="message"
                                          rows="5"
                                          data-rule="required"
                                          data-msg="Schreib uns doch was"
                                          placeholder="Deine Nachricht"
                                          [class.valid]="form.get('message').valid && (form.get('message').dirty || form.get('message').touched)"
                                          [class.invalid]="form.get('message').invalid && (form.get('message').dirty || form.get('message').touched)"></textarea>

                                <div *ngIf="form.get('message').invalid && form.get('message').errors && (form.get('message').dirty || form.get('message').touched)">
                                    <small class="text-danger" *ngIf="form.get('message').hasError('required')">
                                        Bitte sende uns eine aussagekräftige Nachricht zu.
                                    </small>
                                    <small class="text-danger" *ngIf="form.get('message').hasError('minlength')">
                                        Bitte sende uns eine aussagekräftigere Nachricht zu.
                                    </small>
                                </div>
                            </div>


                            <div class="text-center">
                                <button type="submit" [disabled]="form.invalid">Nachricht senden</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <a href="#" class="back-to-top">
        <i class="fa fa-chevron-up"></i>
    </a>

</div>
