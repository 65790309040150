<section id="restaurateurs">
    <div class="container wow fadeInUp">
        <div class="row">
            <div class="col-md-12">
                <h3 class="section-title">kahawa</h3>
                <div class="section-title-divider"></div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-coffee"></i></div>
                <h4 class="restaurateur-title">Individuelle Kaffees</h4>
                <p class="restaurateur-description">
                    Sie bieten Ihren Kunden genau den Kaffee, den sie kennen und lieben. So fühlen sich Ihre Kunden bei Ihnen wie zu Hause.<br>
                    Dazu individuelle, verbrauchs- und zutatenabhängige Preisgestaltung.
                </p>
            </div>
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-line-chart"></i></div>
                <h4 class="restaurateur-title">Höherer Durchsatz</h4>
                <p class="restaurateur-description">
                    Die Kunden können ihre Getränkewahl (orts-)unabhängig von der Kaffeemaschine treffen.
                    Wertvolle Zeit an der Maschine wird gespart, sodass Sie mehr Kunden in einem kürzeren Zeitraum zufriedenstellen können.
                </p>
            </div>
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-group"></i></div>
                <h4 class="restaurateur-title">Individuelle Angebote</h4>
                <p class="restaurateur-description">
                    Wir ermöglichen Ihnen den direkten Kontakt zum Kunden und Ihrem Kunden ein besseres Kaffee-Erlebnis.
                    Das erhöht die Attraktivität Ihres Standorts.
                    <!--                    Sie kennen Ihre Kunden, wir ermöglichen Ihnen einen direkten Kontakt. So wird Ihre Kaffeemaschine-->
                    <!--                    immer bestens ausgelastet und Sie ermöglichen Ihren Kunden ein besseres Kaffeeerlebnis.-->
                </p>
            </div>
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-comment"></i></div>
                <h4 class="restaurateur-title">Betriebs<wbr>unterstützung</h4>
                <p class="restaurateur-description">
                    Wir optimieren Ihren Betriebsablauf: Benötigt Ihre Maschine bspw. Nachschub an frischen Kaffeebohnen oder Milch?
                    Unser System meldet dies automatisch und rechtzeitig.
                </p>
            </div>
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-bar-chart"></i></div>
                <h4 class="restaurateur-title">Kostenberechnung & Forecast</h4>
                <p class="restaurateur-description">
                    Wir helfen Ihnen Ihre Preise zu gestalten und Ihre Gewinne zu sichern:
                    Bspw. mit Berechnungen zum Materialverbrauch und Umsatz zeigen wir Ihnen Ihre
                    aktuelle Gewinnmarge auf und helfen Ihnen Ihre gewünschte Marge zu erzielen.
                </p>
            </div>
            <div class="col-md-6 restaurateur-item">
                <div class="restaurateur-icon"><i class="fa fa-credit-card"></i></div>
                <h4 class="restaurateur-title">Automatische Abrechnung</h4>
                <p class="restaurateur-description">
                    Egal ob Sie ein schon bestehendes Zahlungssystem nutzen oder weitere Systeme einbinden möchten:
                    Wir kümmern uns darum.
                </p>
            </div>
        </div>
    </div>
</section>

<section id="scenarios">
    <div class="row">
        <div class="col-md-12">
            <h3 class="section-title">Einsatz<wbr>möglichkeiten</h3>
            <div class="section-title-divider"></div>
        </div>
    </div>
    <div class="row">
        <ngb-carousel>
        <ng-template ngbSlide>
            <div class="row scenarios-row">
                <div class="col-md-4 text-center scenarios-img">
                    <img src="assets/img/szenario_coworking.jpg" alt="">
                </div>
                <div class="col-md-8 scenarios-content">
                    <h4 class="scenarios-title">Coworking Space</h4>
                    <p class="scenarios-subtitle">Zahlungsgruppen, temporäre Zugangskarten, Dashboard</p>
                    <p>
                        Im Coworking-Space sind verschiedene Firmen und Freelancer vereint.
                        Mithilfe der Zahlungsgruppen von <i>kahawa</i> lässt sich jeder Kaffee einer Abrechnungseinheit zuordnen und abrechnen.<br>
                        Für temporäre Zugänge (z.B. bei Workshops) können digitale Schlüssel oder Zugangskarten für einen bestimmten Zeitraum für den Zugang an die Kaffeemaschine herausgegeben werden.<br>
                        Mehrere Kaffeemaschinen an unterschiedlichen Standorten lassen sich mit dem Dashboard jederzeit im Blick behalten – bei Handlungsbedarf meldet sich außerdem unser digitaler Assistent und erinnert z.B. an das Auffüllen von Bohnen.
                    </p>

                </div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="row scenarios-row">
                <div class="col-md-4 text-center scenarios-img">
                    <img src="assets/img/szenario_canteen.jpg" alt="">
                </div>
                <div class="col-md-8 scenarios-content">
                    <h4 class="scenarios-title">Kantine / Mensa</h4>
                    <p class="scenarios-subtitle">Dashboard, schnelle Auswahl, Standard-Zahlungssysteme</p>
                    <p>
                        Die Verwaltung von Kaffeemaschinen an mehreren Standorten wird dank der <i>kahawa</i>-Dashboards erleichtert:<br>
                        Jederzeit den aktuellen Füllstand und Status im Blick sowie rechtzeitige Benachrichtigungen zum Nachfüllen.<br><br>
                        Kunden können ihren (individualisierten) Lieblingskaffee auch an Ihren Maschinen trinken – die Auswahl geht sogar noch schneller als die Auswahl eines Standardkaffees vom Display.<br>
                        Vorhandene Zahlungssysteme werden auch bei individuellen Kaffees vollständig unterstützt - kein weiteres Nachrüsten notwendig.
                    </p>
                </div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="row scenarios-row">
                <div class="col-md-4 text-center scenarios-img">
                    <img src="assets/img/szenario_hotel.jpg" alt="">
                </div>
                <div class="col-md-8 scenarios-content">
                    <h4 class="scenarios-title">Hotel</h4>
                    <p class="scenarios-subtitle">Gastspezifische & zeitgesteuerte Abrechnung, Dashboard</p>
                    <p>
                        Die Frühstückskaffeemaschine soll zur zusätzlichen Monetarisierung genutzt werden.<br>
                        Hotel-Zugangskarten können mit <i>kahawa</i> ganz einfach zur Abrechnung jedes Kaffees genutzt werden.
                        "Frühstückszeiten mit Rabatt" oder "dauerhaft kostenlos ausschließlich für Stammgäste" ist dabei voll konfigurierbar.<br>

                        Mehrere Kaffeemaschinen an unterschiedlichen Standorten lassen sich mit dem Dashboard jederzeit im Blick behalten – bei Handlungsbedarf meldet sich außerdem unser digitaler Assistent und erinnert z.B. an das Auffüllen von Bohnen.
                    </p>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
    </div>
</section>

<section id="promise">
    <div class="container wow fadeInUp">
        <div class="row">
            <div class="col-md-12">
                <h3 class="promise-title">Unser Produkt für Sie</h3>
                <div class="promise-title-divider"></div>
                <p class="promise-text">
                    Wir glauben an unser Produkt und dass wir Sie damit unterstützen können!<br>
                    Kontinuierlich arbeiten wir deswegen an der Verbesserung und weiteren Features.<br>
                    Wir freuen uns dabei auf Ihr Feedback und was Ihnen am meisten hilft.
                </p>

                <h5 class="promise-title">Monetarisierung</h5>
                <p class="promise-text">
                    Wir sind noch ein junges StartUp welches sich der Digitalisierung des Kaffees und<br>
                    der Unterstützung von gastronomischen Einrichtungen verschrieben hat.<br>
                    <br>
                    Für jede mit <i>kahawa</i> ausgestattete Maschine berechnen wir eine Grundgebühr.<br>
                    Sofern Zahlungsfeatures genutzt werden, erheben wir zusätzlich pro Kaffee einen kleinen weiteren Betrag.
                </p>

                <h5 class="promise-title">Spannend?</h5>
                <p class="promise-text">
                    Wenn wir Sie unterstützen können, melden Sie sich gerne bei Ihrem Kaffeemaschinendistributor<br>
                    oder nutzen Sie unser <a href="#contact">Kontaktformular</a>
                </p>
            </div>
        </div>
    </div>
</section>
