import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingPageComponentComponent } from './landing-page-component/landing-page-component.component';
import { ImprintComponent } from './imprint/imprint.component';
import { RestaurateursComponent } from './restaurateurs/restaurateurs.component';

const routes: Routes = [{
  path: '',
  component: LandingPageComponentComponent
},
{
  path: 'imprint',
  component: ImprintComponent
},
{
  path: 'restaurateurs',
  component: RestaurateursComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
