import {Component} from '@angular/core';
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-restaurateurs',
    templateUrl: './restaurateurs.component.html',
    styleUrls: ['./restaurateurs.component.scss']
})
export class RestaurateursComponent {

    constructor(config: NgbCarouselConfig) {
        config.showNavigationArrows = false;
        config.showNavigationIndicators = true;
        config.wrap = true;
        config.keyboard = false;
        config.pauseOnHover = true;
    }
}
