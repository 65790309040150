<section id="imprint">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3 class="section-title">Impresssum</h3>
                <div class="section-title-divider"></div>
                <div class="section-description">

                    <div style="font-size: 24px;"><b>KaHaWa UG (haftungsbeschränkt)</b></div>

                    <div>
                        <table style="margin-left:auto;margin-right:auto;min-width: 40%">
                            <tr>
                                <th>Geschäftsführer</th>
                                <td>Jan Lukas Braje</td>
                            </tr>
                            <tr style="height: 15px;"></tr>
                            <tr>
                                <th>Adresse</th>
                                <td>Theaterstraße 4 <br>
                                    01067 Dresden</td>
                            </tr>
                            <tr style="height: 15px;"></tr>
                            <tr>
                                <th>Telefonnummer</th>
                                <td>+49 175 7700512</td>
                            </tr>
                            <tr style="height: 15px;"></tr>
                            <tr>
                                <th>Registergericht</th>
                                <td>Amtsgericht Dresden</td>
                            </tr>
                            <tr>
                                <th>Registernummer</th>
                                <td>HRB 38675</td>
                            </tr>
                            <tr style="height: 15px;"></tr>
                            <tr>
                                <th>Verantwortliche i.S.d. <br>§ 55 Abs. 2 RStV</th>
                                <td>Jan Lukas Braje</td>
                            </tr>
                        </table>
                    </div>

                <br>
                <h4 style="color: var(--dark)">Bildnachweise:</h4>
                    <p>Das Copyright für Portraits, Fotos, Hintergrundbilder liegen bei:<br> KaHaWa UG (haftungsbeschränkt), <a href="http://olekaleschke.de">Ole Kaleschke</a> und bei <a href="https://500px.com/mirifoto">Miriam Bentfeld</a>.</p>

                </div>
            </div>
        </div>
    </div>
</section>
