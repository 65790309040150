import {Component, ElementRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-landing-page-component',
    templateUrl: './landing-page-component.component.html',
    styleUrls: ['./landing-page-component.component.scss']
})
export class LandingPageComponentComponent {
    form: FormGroup;

    constructor(private formBuilder: FormBuilder, private http: HttpClient, private elementRef: ElementRef) {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            subject: ['', [Validators.required, Validators.minLength(4)]],
            message: ['', [Validators.required, Validators.minLength(4)]]
        });
    }

    public onClickSubmit() {
        const formData: any = new FormData();
        formData.append('name', this.form.get('name').value);
        formData.append('email', this.form.get('email').value);
        formData.append('subject', this.form.get('subject').value);
        formData.append('message', this.form.get('message').value);

        const contactRequestPayload = {};
        formData.forEach(function (value, key) {
            contactRequestPayload[key] = value;
        });

        this.http.post<any>("https://api.getkahawa.com/v1-website-service/contact", JSON.stringify(contactRequestPayload)).subscribe({
            next: () => {
                const errorMessage = this.elementRef.nativeElement.querySelector('#errormessage');
                errorMessage.style.display = 'none';

                const successMessage = this.elementRef.nativeElement.querySelector('#sendmessage');
                successMessage.style.display = 'block';
            },
            error: () => {
                const errorMessage = this.elementRef.nativeElement.querySelector('#errormessage');
                errorMessage.style.display = 'block';

            },
        });
    }
}
