<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<section>

    <button type="button" id="mobile-nav-toggle" ><i class="fa fa-bars"></i></button>
<header id="header">
    <div class="container" >

        <div id="logo" class="pull-left">
            <a href=""><img src="/assets/img/logo-header.svg" alt="" title="" /></a>
        </div>

        <nav id="nav-menu-container">
            <ul class="nav-menu">
                <li><a href="#about">Was ist kahawa?</a></li>
                <li><a href="#services">Services</a></li>
<!--                <li *ngIf="!isAuthenticated()"><a [routerLink]="createFragmentedUrl('team')" [routerLinkActive]="'active'">Team</a></li>-->
                <li><a href="#contact">Kontakt</a></li>
                <li><a href="restaurateurs">Für Gastronomen</a></li>
                <li><a onclick="window.location.href='https://app.getkahawa.com'" class="btn-login" style="color: #fff">Login</a></li>
            </ul>
        </nav>
    </div>
</header>

</section>
  
<div class="content" role="main">
    <router-outlet></router-outlet>

</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<!--==========================
Footer
============================-->
<footer id="footer">
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <div class="imprint">
                  <a href="imprint">Impressum</a>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="copyright">
                  &copy; <strong>KaHaWa UG (haftungsbeschränkt)</strong> 2022
              </div>
          </div>
      </div>
  </div>
</footer>
