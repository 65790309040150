import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LandingPageComponentComponent} from './landing-page-component/landing-page-component.component';
import {ImprintComponent} from './imprint/imprint.component';
import {RestaurateursComponent} from './restaurateurs/restaurateurs.component';
import {MatIconModule} from "@angular/material/icon";
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponentComponent,
    ImprintComponent,
    RestaurateursComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatTooltipModule,
		MatIconModule,
		FormsModule,
		HttpClientModule,
		NgbModule,
		ReactiveFormsModule,

    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
